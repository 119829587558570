<script>
    let articles = "";
    fetch("/blog/articles").then(response => {
        console.log(response);
        return response.json();
    }).then(json => {
        articles = json;
    });
</script>

<h1>Noticias</h1>

<ul>
    {#each articles as article}
        <li>
            {article.title}
        </li>
    {/each}
</ul>
