<script>
	import { Link } from "svelte-routing";
	const style="text-decoration: none; color: #136a51";
</script>

<header>
	<nav>
		<ul>
			<li><Link to="/noticias" {style}>Noticias</Link></li>
			<li><Link to="/entrevistas" {style}>Entrevistas</Link></li>
			<li><Link to="/acerca-de" {style}>Acerca de</Link></li>
		</ul>
	</nav>
</header>

<style>
	header {
		background: rgba(0, 0, 0);
		width: 100%;
        display: flex;
		z-index: 100;
	}

	nav {
        display: flex;
	}

	nav ul {
		list-style: none;
		overflow: hidden; 
        display: flex;
        flex-direction: row;
		height: 40px;
		padding: 0;
		margin: 0;
	}

	nav ul li {
		font-family: Arial, sans-serif;
		font-size: 18px;
        padding: 0px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	nav ul li:hover {
		background: #d4ac8a;
		height: 100%;
	}
</style>
