<script>
	import { Router, Route } from "svelte-routing";
	import Articles from "./components/Articles.svelte";
	import Interviews from "./components/Interviews.svelte";
	import About from "./components/About.svelte";
    import NavBar from "./components/NavBar.svelte";
  
	export let url = "/acerca-de";
</script>

<Router {url}>
	<NavBar />
	<div>
		<Route path="/noticias" component={Articles} />
		<Route path="/entrevistas" component={Interviews} />
		<Route path="/acerca-de" component={About} />
	</div>
</Router>
